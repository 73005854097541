var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-scrollbar",
        { staticClass: "wp-scrollbar", staticStyle: { height: "100%" } },
        [
          _vm.tabsList.length > 1
            ? _c("div", { staticClass: "title-header" }, [
                _c(
                  "div",
                  { staticClass: "title-header-left" },
                  [
                    _c("UnitTab", {
                      attrs: {
                        tabsList: _vm.tabsList,
                        activeId: _vm.activeName,
                      },
                      on: { handleClick: _vm.handleClick },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "main" },
            [
              _c("SearchCard", {
                attrs: { title: "阅评中心" },
                on: { handlerSearch: _vm.handlerSearch },
                scopedSlots: _vm._u([
                  {
                    key: "searchContent",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "wp-date-picker search-w392" },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "default-time": ["00:00:00", "23:59:59"],
                                type: "datetimerange",
                                "prefix-icon": "",
                                format: "yyyy-MM-dd HH:mm",
                                "value-format": "yyyy-MM-dd HH:mm",
                                clearable: "",
                                "range-separator": "-",
                                "start-placeholder": "请选择开始时间",
                                "end-placeholder": "请选择结束时间",
                              },
                              on: { change: _vm.changeTimeSection },
                              model: {
                                value: _vm.timeSection,
                                callback: function ($$v) {
                                  _vm.timeSection = $$v
                                },
                                expression: "timeSection",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-input", {
                          staticClass: "search-w392 wp-input",
                          attrs: { clearable: "", placeholder: "请输入标题" },
                          model: {
                            value: _vm.searchParams.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "title", $$v)
                            },
                            expression: "searchParams.title",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticClass: "search-w190 wp-select",
                            attrs: { clearable: "", placeholder: "请选择状态" },
                            model: {
                              value: _vm.searchParams.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "status", $$v)
                              },
                              expression: "searchParams.status",
                            },
                          },
                          _vm._l(
                            _vm.$globalData.REVIEW_CENTER_STATUS,
                            function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.id },
                              })
                            }
                          ),
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "wp-card mt-16" },
                [
                  _c("div", { staticClass: "flex-CB" }, [
                    _c("div", { staticClass: "fs-14 flex" }),
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "preventReClick",
                                rawName: "v-preventReClick",
                              },
                            ],
                            staticClass: "wp-button wp-btn-icon",
                            attrs: {
                              type: "primary",
                              icon: "icon-tianjia",
                              size: "medium",
                            },
                            on: { click: _vm.handlerUpload },
                          },
                          [_vm._v(" 阅评上报 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "wp-table mt-20",
                      attrs: {
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-text": "正在加载",
                      },
                    },
                    [
                      _vm.tableData.length !== 0
                        ? _c(
                            "el-table",
                            { attrs: { data: _vm.tableData, stripe: "" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "50",
                                  label: "序号",
                                  type: "index",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "left",
                                  prop: "title",
                                  label: "标题",
                                  "min-width": "95",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "稿件",
                                  "min-width": "70",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("img", {
                                            staticClass: "wp-pointer",
                                            staticStyle: {
                                              width: "22px",
                                              height: "22px",
                                            },
                                            attrs: {
                                              src: _vm.$utils.handlePreviewImg(
                                                scope.row.fileJson
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$utils.download(
                                                  _vm.BASE_API +
                                                    "/file/" +
                                                    scope.row.fileJson[0].id
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3589273767
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "70",
                                  align: "center",
                                  label: "终稿",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.fileJsonEnd == ""
                                            ? _c("span", [_vm._v("-")])
                                            : _c("img", {
                                                staticClass: "wp-pointer",
                                                staticStyle: {
                                                  width: "22px",
                                                  height: "22px",
                                                },
                                                attrs: {
                                                  src: _vm.$utils.handlePreviewImg(
                                                    scope.row.fileJsonEnd
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$utils.download(
                                                      _vm.BASE_API +
                                                        "/file/" +
                                                        scope.row.fileJsonEnd[0]
                                                          .id
                                                    )
                                                  },
                                                },
                                              }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  210879724
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "70",
                                  align: "center",
                                  prop: "words",
                                  label: "字数",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "textOverOneLine" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.words
                                                      ? scope.row.words
                                                      : "-"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3507255206
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "70",
                                  align: "center",
                                  prop: "royalties",
                                  label: "稿酬",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "textOverOneLine" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.royalties
                                                      ? scope.row.royalties
                                                      : "-"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1532136998
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "min-width": "120",
                                  align: "center",
                                  prop: "createTime",
                                  label: "提交时间",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "textOverOneLine" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .$moment(
                                                        scope.row.createTime
                                                      )
                                                      .format(
                                                        "YYYY-MM-DD HH:mm"
                                                      )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3175648700
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "status",
                                  label: "状态",
                                  "min-width": "70",
                                  "max-width": "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("div", [
                                            scope.row.status == 3
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "flex-center",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "success-circle",
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "success-color",
                                                      },
                                                      [_vm._v("已采用")]
                                                    ),
                                                  ]
                                                )
                                              : scope.row.status == 2
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "flex-center",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "disabled-circle",
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "disabled-color",
                                                      },
                                                      [_vm._v("已读")]
                                                    ),
                                                  ]
                                                )
                                              : scope.row.status == 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "flex-center",
                                                  },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "noreceive-circle",
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "noreceive-color",
                                                      },
                                                      [_vm._v("未读")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3611525904
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "操作",
                                  fixed: "right",
                                  width: "90",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "flex-center" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "wp-action-icon",
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "编辑",
                                                        placement: "top",
                                                        "visible-arrow": false,
                                                        "popper-class":
                                                          "wp-btn-tooltip",
                                                      },
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        staticClass:
                                                          "wp-btn-icon-big",
                                                        class: !scope.row.edit
                                                          ? "no-drop"
                                                          : "",
                                                        attrs: {
                                                          type: "text",
                                                          icon: "icon-bianji1",
                                                          disabled:
                                                            !scope.row.edit,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editItem(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "wp-action-icon",
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "删除",
                                                        placement: "top",
                                                        "visible-arrow": false,
                                                        "popper-class":
                                                          "wp-btn-tooltip",
                                                      },
                                                    },
                                                    [
                                                      _c("el-button", {
                                                        staticClass:
                                                          "wp-btn-icon-big",
                                                        class: !scope.row.del
                                                          ? "no-drop"
                                                          : "",
                                                        attrs: {
                                                          type: "text",
                                                          icon: "icon-shanchu",
                                                          disabled:
                                                            !scope.row.del,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delItem(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2784491617
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length == 0 && !_vm.loading
                        ? _c("div", { staticClass: "wp-noData" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/noData.png"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("wp-pagination", {
                    staticClass: "mt-21",
                    attrs: {
                      total: _vm.total,
                      currentPage: _vm.searchParams.page,
                      pageSize: _vm.searchParams.limit,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("UploadFiles", {
        ref: "uploadFiles",
        attrs: { userObj: _vm.userObj, reviewObj: _vm.reviewObj },
        on: {
          success: _vm.reviewSuccess,
          close: function ($event) {
            _vm.reviewObj = {}
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }