
import {
	login,
	getInfo,
	getUserList,
	changeUser,
	loginOut,
	threepartylogin,
	postlogin,
	xjxqLogin
} from '@/api/login'
import {
	loginWXG,
	getInfoWXG
} from '@/api/wxgLogin'
import {
	getToken,
	setToken,
	removeToken
} from '@/utils/auth'
import utils from '@/utils/utils'
const user = {
	state: {
		token: getToken(),
		name: '',
		roles: [],
		permissions: [],
		userInfo: {},
		roleId: '',
		userList: [],
		sysCust: {},
		sysUserInfo: {},
		warn: '',
		userId: '',
		isWpyAndAdmins: false, // 多管理员且有网评员
		isWpyAndAdmin: false, // 单管理员且有网评员
		isWpys: false, // 是否为多网评员
		isWpy: false, // 是否为单网评员
		wpyUnitTabs: [], // 网评员单位标签页
		notif: '',
		wpyUserList: [],// 多网评员列表
		reviewCustList: [],// 有阅评权限的网评员账号单位列表
		excellentCustList: [] // 有优评上报权限的组长账号单位列表
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_NAME: (state, name) => {
			state.name = name
		},
		SET_ROLEID: (state, roleId) => {
			state.roleId = roleId
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_PERMISSIONS: (state, permissions) => {
			state.permissions = permissions
		},
		SET_USERINFO: (state, userInfo) => {
			state.userInfo = userInfo
		},
		SET_USRE_LIST: (state, userList) => {
			state.userList = userList
		},
		SET_SYSCUST: (state, sysCust) => {
			state.sysCust = sysCust
		},
		SET_SYSUSER_INFO: (state, sysUserInfo) => {
			state.sysUserInfo = sysUserInfo
		},
		SET_WARN: (state, warn) => {
			state.warn = warn
		},
		SET_NOTIF: (state, notif) => {
			state.notif = notif
		},
		SET_USERID: (state, userId) => {
			state.userId = userId
		},
		SET_ISWPYANDADMIN: (state, res) => {
			// 管理员,组长
			const arr = [2, 3]
			// 管理员,网评员
			const arr2 = [2, 4]
			// 角色Id集合
			const rule = res.map(item => item.roleId)
			// 是否同时拥有管理员 网评员或组长 两种角色
			const flag = utils.isContained(rule, arr) || utils.isContained(rule, arr2)

			if (flag) {
				// 判断管理员个数
				const num = rule.reduce((prev, curr) => {
					if (curr == 2) {
						prev += 1
					}
					return prev
				}, 0)

				// 单管理员
				if (num == 1) {
					state.isWpyAndAdmins = false
					state.isWpyAndAdmin = true
				} else {
					state.isWpyAndAdmin = false
					state.isWpyAndAdmins = true
				}
			} else {
				state.isWpyAndAdmin = false
				state.isWpyAndAdmins = false
			}
		},
		SET_ISWPYS: (state, res) => {
			// 角色Id集合
			const rule = res.map(item => item.roleId)
			// 判断管理员个数
			const num = rule.reduce((prev, curr) => {
				// 3:组长 4:网评员
				if (curr == 3 || curr == 4) {
					prev += 1
				}
				return prev
			}, 0)
			if (num > 1) {
				state.isWpys = true
			} else if (num == 1) {
				state.isWpy = true
			} else {
				state.isWpys = false
				state.isWpy = false
			}
		},
		SET_WPYUNITTABS: (state, res) => {
			let tabs = res.filter(it => it.roleId == 3 || it.roleId == 4).map(item => { return { id: item.userId.toString(), custId: item.sysCust.custId + '', label: item.sysCust.aliasName, news: 0 } })
			console.log(tabs)
			if (tabs.length > 1) {
				tabs.unshift({ label: '全部', custId: '' })
			}
			state.wpyUnitTabs = tabs
		},
		SET_USRE_LIST_WPY: (state, res) => {
			state.wpyUserList = res.filter(it => it.roleId == 3 || it.roleId == 4)
		},
		SET_REVIEW_CUST_LIST: (state, res) => {
			state.reviewCustList = res
		},
		SET_EXCELLENT_CUST_LIST: (state, res) => {
			state.excellentCustList = res
		},

	},

	actions: {
		// 登录
		Login ({
			commit
		}, loginInfo) {
			return new Promise((resolve, reject) => {
				login(loginInfo).then(res => {
					if (Number(res.returnCode) !== 200) return resolve(res)
					commit('SET_TOKEN', getToken())
					localStorage.clear();
					localStorage.setItem('isWXG', 1)
					if (res.returnData.warn) {
						commit('SET_WARN', res.returnData.warn)
					} else {
						commit('SET_WARN', '')
					}
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 登录
		LoginWXG ({
			commit
		}, loginInfo) {
			return new Promise((resolve, reject) => {
				loginWXG(loginInfo).then(res => {
					if (Number(res.returnCode) !== 200) return resolve(res)
					commit('SET_TOKEN', getToken())
					localStorage.clear();
					localStorage.setItem('isWXG', 2)
					if (res.returnData.warn) {
						localStorage.setItem('SET_WARN', res.returnData.warn)
					} else {
						localStorage.setItem('SET_WARN', '')
					}
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 切换账号
		// 登录
		LoginChangeUser ({
			commit
		}, loginInfo) {
			return new Promise((resolve, reject) => {
				changeUser(loginInfo).then(res => {
					if (Number(res.returnCode) !== 200) return resolve(res)
					commit('SET_TOKEN', getToken())
					if (res.returnData.warn) {
						commit('SET_WARN', res.returnData.warn)
					} else {
						commit('SET_WARN', '')
					}

					commit('SET_ROLES', [])
					commit('SET_ROLEID', '')
					commit('SET_USERINFO', {})
					// 当前的单位 当前的角色名称  角色ID；1超级管理员；2管理员；3组长；4网评员
					commit('SET_SYSCUST', {})
					commit('SET_SYSUSER_INFO', {})
					// commit('SET_REMINDNUM', 1)
					// sessionStorage.setItem(`isFirstLogin`,true)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 常德第三方登录
		Threepartylogin ({
			commit
		}, query) {
			return new Promise((resolve, reject) => {
				threepartylogin(query).then(res => {
					if (Number(res.returnCode) !== 200) return resolve(res)
					// setToken('islogin')
					// commit('SET_TOKEN', 'islogin')
					commit('SET_TOKEN', getToken())
					if (res.returnData.warn) {
						commit('SET_WARN', res.returnData.warn)
					} else {
						commit('SET_WARN', '')
					}

					commit('SET_ROLES', [])
					commit('SET_ROLEID', '')
					commit('SET_USERINFO', {})
					// 当前的单位 当前的角色名称  角色ID；1超级管理员；2管理员；3组长；4网评员
					commit('SET_SYSCUST', {})
					commit('SET_SYSUSER_INFO', {})

					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 获取账号列表
		GetUserList ({
			commit, state
		}) {
			return new Promise((resolve, reject) => {
				getUserList({}).then(res => {
					console.log('state.userInfo')
					console.log(state.userInfo.userId)
					if (Number(res.returnCode) !== 200) return resolve(res)
					// 如何保存？ 在路由切换时，判断数组中有无元素，没有就重新获取一次？
					res.returnData.forEach(element => {
						element.aliasName = element.sysCust.custName
						if (element?.sysCust?.aliasName) {
							element.aliasName = element.sysCust.aliasName
						}
					});

					commit('SET_USRE_LIST', res.returnData)
					// 判断单管理员网评员 还是 多管理员网评员
					commit('SET_ISWPYANDADMIN', res.returnData)
					// 判断单网评员还是多网评员
					commit('SET_ISWPYS', res.returnData)
					// 获取网评员单位列表
					commit('SET_WPYUNITTABS', res.returnData)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 获取用户信息 路由菜单权限
		GetInfo ({
			commit, state
		}) {
			return new Promise((resolve, reject) => {
				getInfo({}).then(res => {
					if (Number(res.returnCode) !== 200) return resolve(res)
					// commit('SET_ROLES', ['ROLE_DEFAULT'])
					commit('SET_ROLES', [res.returnData.sysUser.roleId])
					commit('SET_ROLEID', res.returnData.sysUser.roleId)
					commit('SET_USERINFO', res.returnData.sysUser)
					commit('SET_USERID', res.returnData.sysUser.userId)

					if (res.returnData.sysCustOption.notification == 1) {
						//第二种: 不仅识别red 其他有闭合标签的都会自动被html 识别未标签
						commit('SET_NOTIF', res.returnData.sysCustOption.notificationContent.replace(/<red>/g, '<span style="color: #F35252;">').replace(/<\/red>/g, '</span>'))

					} else {
						commit('SET_NOTIF', '')
					}
					// 当前的单位 当前的角色名称  角色ID；1超级管理员；2管理员；3组长；4网评员
					commit('SET_SYSCUST', res.returnData.sysCust)
					commit('SET_SYSUSER_INFO', res.returnData)
					// 把本单位的网评员放在最前面
					//userId
					let sysUser = res.returnData.sysUser
					// 有阅评权限的单位 账号
					if (res.returnData.reviewCustList && res.returnData.reviewCustList.length > 0) {
						let reviewCustList = JSON.parse(JSON.stringify(res.returnData.reviewCustList))
						let reviewUsers = reviewCustList.filter((item) => sysUser.userId == item.userId)

						let ind = reviewCustList.findIndex((item) => sysUser.userId == item.userId)
						if (ind > 0) {
							reviewCustList.splice(ind, 1)
							reviewCustList.unshift(reviewUsers[0])
						}
						commit('SET_REVIEW_CUST_LIST', reviewCustList)
					}
					// 有优评上报权限的单位且是组长的账号
					if (res.returnData.excellentCustList && res.returnData.excellentCustList.length > 0) {
						let excellentCustList = JSON.parse(JSON.stringify(res.returnData.excellentCustList))
						let excellentUsers = excellentCustList.filter((item) => sysUser.userId == item.userId)
						// 排序
						let ind = excellentCustList.findIndex((item) => sysUser.userId == item.userId)
						if (ind > 0) {
							excellentCustList.splice(ind, 1)
							excellentCustList.unshift(excellentUsers[0])
						}
						commit('SET_EXCELLENT_CUST_LIST', excellentCustList)
					}


					let userList = JSON.parse(JSON.stringify(state.userList))
					let users = userList.filter((item) => sysUser.userId == item.userId)

					let index = userList.findIndex((item) => sysUser.userId == item.userId)
					if (index > 0) {
						userList.splice(index, 1)
						userList.unshift(users[0])
					}
					console.log(userList)
					commit('SET_USRE_LIST_WPY', userList)
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 获取用户信息 路由菜单权限
		GetInfoWXG ({
			commit, state
		}) {
			return new Promise((resolve, reject) => {
				getInfoWXG({}).then(res => {
					return resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
		// 退出系统
		LogOut ({
			commit,
			state
		}) {
			return new Promise((resolve, reject) => {
				loginOut().then(() => {
					commit('SET_USRE_LIST', [])
					commit('SET_TOKEN', '')
					commit('SET_ROLES', [])
					commit('SET_ROLEID', '')
					commit('SET_USERINFO', {})
					commit('SET_SYSCUST', {})
					removeToken()
					localStorage.clear();
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 前端 登出
		FedLogOut ({
			commit
		}) {
			return new Promise(resolve => {
				commit('SET_USRE_LIST', [])
				commit('SET_TOKEN', '')
				commit('SET_ROLES', [])
				commit('SET_ROLEID', '')
				commit('SET_USERINFO', {})
				removeToken()
				localStorage.clear();
				resolve()
			})
		},
		// 清除 用户信息缓存
		ClearUser ({
			commit
		}) {
			return new Promise(resolve => {
				commit('SET_USRE_LIST', [])
				// commit('SET_TOKEN', '')
				commit('SET_ROLES', [])
				commit('SET_ROLEID', '')
				commit('SET_USERINFO', {})
			})
		},

		// 红网中心平台 第三方登录
		XjxqLogin ({
			commit
		}, query) {
			return new Promise((resolve, reject) => {
				xjxqLogin(query).then(res => {
					if (Number(res.returnCode) !== 200) return resolve(res)
					localStorage.clear();
					commit('SET_TOKEN', getToken())
					if (res.returnData.warn) {
						commit('SET_WARN', res.returnData.warn)
					} else {
						commit('SET_WARN', '')
					}

					commit('SET_ROLES', [])
					commit('SET_ROLEID', '')
					commit('SET_USERINFO', {})
					// 当前的单位 当前的角色名称  角色ID；1超级管理员；2管理员；3组长；4网评员
					commit('SET_SYSCUST', {})
					commit('SET_SYSUSER_INFO', {})
					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 湘江新区 第三方登录
		Postlogin ({
			commit
		}, query) {
			return new Promise((resolve, reject) => {
				postlogin(query).then(res => {
					if (Number(res.returnCode) !== 200) return resolve(res)
					localStorage.clear();
					commit('SET_TOKEN', getToken())
					if (res.returnData.warn) {
						commit('SET_WARN', res.returnData.warn)
					} else {
						commit('SET_WARN', '')
					}

					commit('SET_ROLES', [])
					commit('SET_ROLEID', '')
					commit('SET_USERINFO', {})
					// 当前的单位 当前的角色名称  角色ID；1超级管理员；2管理员；3组长；4网评员
					commit('SET_SYSCUST', {})
					commit('SET_SYSUSER_INFO', {})

					resolve(res)
				}).catch(error => {
					reject(error)
				})
			})
		},
	}
}

export default user
