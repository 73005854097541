var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.fileJson, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "video-box" },
          [
            _c("el-image", {
              staticClass: "video-img",
              attrs: { fit: "cover", src: item.path },
              on: { click: _vm.handleClickItem },
            }),
            _c("img", {
              staticClass: "play",
              staticStyle: { width: "40px", height: "40px" },
              attrs: {
                src: require("@/assets/images/bofang.png"),
                alt: "播放",
              },
              on: { click: _vm.playVideo },
            }),
          ],
          1
        )
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.videoVisible,
              expression: "videoVisible",
            },
          ],
          staticClass: "wp-mask",
        },
        [
          _c("img", {
            staticClass: "close",
            attrs: { src: require("@/assets/images/guanbi.png"), alt: "关闭" },
            on: { click: _vm.hideMask },
          }),
          _c("video", {
            ref: "video",
            staticClass: "video-player",
            attrs: {
              controls: "",
              width: "250",
              src:
                _vm.process.env.VUE_APP_BASE_API +
                "/file/ios/download/" +
                _vm.fileJson[0].id,
              type: "video/mp4",
            },
          }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }