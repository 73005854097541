import router from './router'
import store from './store'
import {
	Message
} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {
	getToken
} from '@/utils/auth'
NProgress.configure({
	showSpinner: false
})
const whiteList = ['/login', '/forgetPassword', '/wpUi', '/outside', '/404', '/outsite', '/newOutSide', '/ThirdLogin', '/xjxqLogin','/noPermission']
const threeLogins = ['/outside', '/newOutSide', '/ThirdLogin','/xjxqLogin','/outsite']
import ENUM from '@/utils/enum.js'
// 可见的的菜单路由
let tabs = []
// 所有的路由菜单示例
let tabs2 = [
	'switchLogin',// 多角色-切换账号

	'uploadTest', // 上传文件测试

	"homeAdmin",//管理员-首页
	'home',// 网评员-首页

	'messageCenter',// 管理员-消息中心
	'wpMessageCenter',// 网评员-消息中心

	'teamlist',// 管理员或者组长-人员队伍-列表
	'addPeople',// 管理员-人员队伍-添加人员
	'peopleInformation',// 管理员或者组长-人员队伍-个人资料

	"taskManagement",// 管理员-投稿中心-投稿管理
	"taskReview",// 管理员-投稿中心-投稿审核
	"taskStatistics",// 管理员-投稿中心-投稿统计
	"transpondStatistics", //管理员-投稿中心-转发统计
	"taskAnalyse",// 管理员-投稿中心-投稿分析
	'addTask',// 管理员-投稿中心-添加任务
	'taskDetail',// 管理员-投稿中心-任务详情
	'batchTask',// 管理员-投稿中心-批量任务
	'editTask', // 管理员-投稿中心-编辑任务
	'addTaskPeople', // 管理员-投稿中心-追加人员

	'taskList', // 网评员-投稿中心-投稿列表
	'myWork', // 网评员-投稿中心-我的作品
	'workDetail', // 网评员-投稿中心-作品详情
	'wordStatistics', // 网评员-投稿中心-作品统计
	'crewStatistics', // 网评员-投稿中心-组员统计
	'taskCenterDetail', // 网评员-投稿中心-投稿详情
	'taskCenterTranspondTask', // 网评员投稿中心-转发任务

	'reportedCenterStatistics', // 网评员-上报统计
	'crewReportedStatistics', // 网评员-组员统计
	'myReported', // 网评员-我的上报
	'articleDetail', // 网评员-我的上报-上报详情
	'specialList', // 网评员-专题列表
	'specialDetail', // 网评员-专题详情

	'reportedStatistics', // 管理员-上报统计
	'reportedReview', // 管理员-上报审核
	'reportedReviewDetail', // 管理员-上报审核-详情
	'reportedManage', // 管理员-上报管理
	'reportedDetail', // 管理员-上报管理-详情
	'addSpecial', // 管理员-上报管理-创建专题
	'addToPeople', // 管理员-上报管理-追加人员
	'editTopic', // 管理员-上报管理-编辑专题

	'drillList',//管理员-投稿演练列表页
	'wpydrillList', //网评员-投稿演练列表页
	'drillMembers',//管理员-演练成员列表页
	'integralMall',//网评员-积分中心
	'personalInformation',// 系统设置-个人信息-管理员
	'leaderPersonalInformation',// 网评员和组长-系统设置-个人信息
	'changePassword',// 系统设置-修改密码
	'parameterConfig',// 管理员-系统设置-参数配置
	'unitManagList',// 超级管理员-系统设置-单位管理
	'siteConfigList',// 超级管理员-系统设置-全局配置
	'createUnits',// 超级管理员-系统设置-创建单位
	'userManagList',// 超级管理员-系统设置-单位管理-用户管理
	'addUser',// 超级管理员-系统设置-添加用户
	'smsManagList',// 超级管理员-系统设置-短信管理
	'smsLogList',// 超级管理员-系统设置-短信日志
	// 	（管理员）detailHeaderPlayback（演练回放），drillDetailPlayback（演练回放），bulletChatPlayback（演练回放），drillList（演练列表），drillMembers（演练成员），appendMembers（演练追加成员），addDrill（创建和编辑演练）
	// （管理员和网评员）detailHeader（演练详情），drillDetail（演练详情），bulletChat（演练详情），
	// （网评员）wpydrillList（网评员演练列表）
]
//模拟路由跳转
router.beforeEach((to, from, next) => {
	if(threeLogins.indexOf(to.path) == -1 && localStorage.getItem('isWXG') && localStorage.getItem('isWXG') == 2){
		return window.location.href = window.location.origin+'/wxg/login'
	}
	NProgress.start()
	//验证是否有token
	if (getToken()) {
		/* has token*/
		if (whiteList.indexOf(to.path) !== -1) {
			next()
		} else {
			// 判断有没有生成菜单，没有就获取一次用户信息，生成可见菜单
			// 有生成菜单 判断是否有可访问的权限，没有权限就弹出没有权限的弹窗，并且不跳转路由
			if (store.getters.roles.length === 0 || store.getters.userList.length === 0) {// 判断有没有用户列表
				Promise.all([store.dispatch('GetUserList'), store.dispatch('GetInfo')]).then(resList => {
					// 请求异常的
					if (resList.filter(it => Number(it.returnCode) !== 200).length > 0) {
						store.dispatch('FedLogOut').then(() => {
							next({
								path: '/login'
							})
						})
					}
					let res = resList[1]
					// 都请求正常
					if (res.returnData.firstLogin) {
						// 首次登录 清除token
						return next({
							path: '/login'
						})
					}
					// 在tabs中插入'/'? 识别sysCustOption 中的 accessDrill	string	投稿演练 0.禁用 1.启用
					// accessShop 积分兑换 0.禁用 1.启用 
					tabs = res.returnData.menuList
					// 赣州
					let list = store.getters.sysCust.custId == 2?['addDrill','drillList','drillMembers','wpydrillList','appendMembers']:[]
					let arr = JSON.parse(JSON.stringify(tabs)).filter(it=>list.indexOf(it) == -1)

					// let arr = JSON.parse(JSON.stringify(tabs))
					
					let sysCustOption = store.getters.sysUserInfo.sysCustOption
					const reviewCustListAdmin = store.getters.sysUserInfo.reviewCustListAdmin || []
					const sysUser = store.getters.sysUserInfo.sysUser
					let deletArray = []
					console.log('process.env.VUE_APP_NAME',process.env.VUE_APP_NAME)
					// 没开通投稿演练 将投稿演练模块的路由 列入要删除的数组
					if (Number(sysCustOption.accessDrill) == 0) {
						deletArray = [...deletArray, ...['drillList', 'wpydrillList']]
					}
					// 没开通积分商城 将指积分商城模块的路由 列入要删除的数组
					if (Number(sysCustOption.accessShop) == 0) {
						deletArray = [...deletArray, ...['integralMall']]
					}
					// 没开通阅评 将指阅评的路由 列入要删除的数组
						
					if (Number(sysCustOption.accessReview) == 0 ) {
						deletArray = [...deletArray, ...['review', 'reviewCenter']]
					}else if(reviewCustListAdmin.length > 0 && reviewCustListAdmin.findIndex(it=>it.userId == sysUser.userId) == -1 && sysUser.roleId == ENUM.SYSROLE.ADMIN){
             // 已开通阅评的单位 且是管理员角色 且reviewCustListAdmin不为空的，且账号不在数组中的也没有阅评权限
						 deletArray = [...deletArray, ...['review', 'reviewCenter']]
					}
					// 没开通优评的 列入要删除的数组
					if (Number(sysCustOption.accessExcellentReview) == 0) {
						deletArray = [...deletArray, ...['excellentReportManage', 'excellentReport']]
					}
         
					//定义有权限的数组
					let visibleTabs = []
					//遍历所有路由 
					arr.forEach(item => {
						// 要删除的数组中不包含该路由的  并且 自己里面也没有重复的 则添加到有权限数组中
						if (deletArray.indexOf(item) == -1 && visibleTabs.indexOf(item) == -1) {
							visibleTabs.push(item)
						}
					})
					arr = JSON.parse(JSON.stringify(arr)).filter(it=>visibleTabs.indexOf(it) != -1)
					 console.log('visibleTabs',visibleTabs)
					store.dispatch('GenerateRoutesTab', { tabs: [...arr, 'systemSettings', '404'], visibleTabs: visibleTabs }).then(accessRoutes => {
						router.addRoutes(accessRoutes) // 动态添加可访问路由表
						if (hasPermissionRouter(visibleTabs, to)) {
							console.log(accessRoutes)
							next({
								...to,
								replace: true
							}) // hack方法 确保addRoutes已完成
						} else {
							next({
								...from,
								replace: true
							}) // hack方法 确保addRoutes已完成
						}
					})
				}).catch(err => {
					store.dispatch('FedLogOut').then(() => {
						Message.error(err)
						next({
							path: '/'
						})
					})
				})
			} else {
				if (hasPermissionRouter(store.getters.visible_tabs, to)) {
					next()
				} else {
					next({
						...from,
						replace: true
					}) // hack方法 确保addRoutes已完成
				}
			}
		}
	} else {
		// 没有token
		if (whiteList.indexOf(to.path) !== -1) {
			// 在免登录白名单，直接进入
			next()
		} else {
			next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
		}
	}
})

// 是否有权限访问路由
function hasPermissionRouter (visibleTabs, to) {
	let toPath = to.name ? to.name : to.path
	if (whiteList.indexOf(toPath) != -1) return true
	return visibleTabs.some(tab => toPath.includes(tab))
}
router.afterEach(() => {
	NProgress.done()
})
