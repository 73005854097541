<template>
  <div class="peopleInformation">
    <el-scrollbar class="wp-scrollbar" style="height: 100%;">
      <div class="layout-main">
        <div>
          <el-breadcrumb separator-class="el-icon-arrow-right" class="wp-breadcrumb">
            <el-breadcrumb-item :to="{ name: 'teamlist' }">人员队伍</el-breadcrumb-item>
            <el-breadcrumb-item>个人信息</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 个人信息 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-title">个人信息
          </div>
          <div class="layout-content">
            <div class="layout-content-top">
              <!-- 头像分男女 性别 1.男 2.女-->
              <div class="user-avtar"><img src="~@/assets/images/nan.png" alt="" v-if="userObj.sex == 1" />
                <img src="~@/assets/images/nv.png" alt="" v-else-if="userObj.sex == 2">
              </div>
              <div class="user-info">
                <p class="user-name">{{ userObj.userName }}</p>
                <p class="user-phone">{{ userObj.mobile }}</p>
                <div class="star">
                  <el-rate v-model="userObj.starLevel" disabled :colors="['#FAA548', '#FAA548', '#FAA548']" void-color="#D8D8D8">
                  </el-rate>
                </div>
                <div class="info-row">
                  <div class="info-item">
                    <span class="info-item-label">积分总数：</span>
                    <p class="info-item-text">{{ allUserObj.totalScore }}</p>
                  </div>
                  <div class="info-item">
                    <span class="info-item-label">本月积分排名：</span>
                    <p class="info-item-text  ">{{ allUserObj.monthScoreRanking }}</p>
                  </div>
                  <div class="info-item">
                    <span class="info-item-label">最后登录时间：</span>
                    <p class="info-item-text  ">{{ userObj.loginTime ? userObj.loginTime : '暂未登录' }}</p>
                  </div>
                  <div class="info-item">
                    <span class="info-item-label">注册时间：</span>
                    <p class="info-item-text  ">{{ userObj.createTime && $moment($utils.resetTime(userObj.createTime)).format("YYYY-MM-DD HH:mm") }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="layout-content-bot">
              <div class="info-row">
                <div class="info-item">
                  <span class="info-item-label">登录账号：</span>
                  <el-tooltip class="item" effect="dark" :content="userObj.loginName" placement="top" :disabled="!isShowTooltipWidth" :tabindex="3">
                    <p class="info-item-text textOverOneLine" @mouseenter="visibilityChangeWidth($event)">{{ userObj.loginName }}</p>
                  </el-tooltip>
                </div>
                <div class="info-item">
                  <span class="info-item-label">所在分组：</span>
                  <el-tooltip class="item" effect="dark" :content="sysGroup.groupName" placement="top" :disabled="!isShowTooltipWidth" :tabindex="2">
                    <p class="info-item-text  textOverOneLine" @mouseenter="visibilityChangeWidth($event)">{{ sysGroup.groupName }}</p>
                  </el-tooltip>
                </div>
              </div>
              <div class="info-row">
                <div class="info-item">
                  <span class="info-item-label">人员角色：</span>
                  <p class="info-item-text textOverOneLine">{{ $globalData.roleNames[userObj.roleId] }}</p>
                </div>
                <div class="info-item">
                  <span class="info-item-label">单位名称：</span>
                  <el-tooltip class="item" effect="dark" :content="userObj.custName" placement="top" :disabled="!isShowTooltipWidth" :tabindex="1">
                    <p class="info-item-text textOverOneLine" @mouseenter="visibilityChangeWidth($event)">{{ userObj.custName }}</p>
                  </el-tooltip>

                </div>
              </div>
              <div class="info-row">
                <div class="info-item">
                  <span class="info-item-label">开户银行：</span>
                  <el-tooltip class="item" effect="dark" :content="userObj.bankName" placement="top" :disabled="!isShowTooltipWidth" :tabindex="6">
                    <p class="info-item-text textOverOneLine" @mouseenter="visibilityChangeWidth($event)">{{ userObj.bankName }}</p>
                  </el-tooltip>

                </div>
                <div class="info-item">
                  <span class="info-item-label">银行账号：</span>
                  <p class="info-item-text textOverOneLine">{{ userObj.bankCode }}</p>
                </div>
              </div>
              <div class="info-row">
                <div class="info-item">
                  <span class="info-item-label">开户行行号：</span>
                  <el-tooltip class="item" effect="dark" :content="userObj.openBankCode" placement="top" :disabled="!isShowTooltipWidth" :tabindex="7">
                    <p class="info-item-text textOverOneLine" @mouseenter="visibilityChangeWidth($event)">{{ userObj.openBankCode }}</p>
                  </el-tooltip>

                </div>
                
              </div>
            </div>
          </div>
        </div>
        <!-- 档案材料 -->
        <!-- 点击图片放大，放大的图片可以左右切换查看。点击图片外区域，退出浮层。 -->
        <div class="layout-block wp-moudle-style" v-if="srcList.length > 0">
          <div class="layout-title">档案材料
          </div>
          <div class="layout-content">
            <ul class="img-box">
              <li v-for="(item, index) in srcList" :key="index">
                <el-image @click="handleClickItem" fit="cover" style="width: 100%; height: 100%" :src="item" :preview-src-list="$utils.arrayRearrangement(index, srcList)">
                </el-image>
              </li>
              <li v-for="(item, index) in (5 - srcList.length)" :key="index + 10">

              </li>
            </ul>
          </div>
        </div>
        <!-- 媒体账号 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-title">媒体账号
          </div>
          <div class="layout-content">
            <div class="layout-content-bot">
              <div class="info-row" v-for="(item, index) in mediaList" :key="index">
                <div class="info-item" v-for="it in item" :key="it.userMediaId">
                  <span class="info-item-label">{{ it.mediaName }}：</span>
                  <el-tooltip class="item" effect="dark" :content="it.nickName" placement="top" :disabled="!isShowTooltipWidth" :tabindex="it.userMediaId">
                    <p class="info-item-text textOverOneLine" @mouseenter="visibilityChangeWidth($event)">{{ it.nickName }}</p>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 值班账号 -->
        <div class="layout-block wp-moudle-style" v-if="sysUserInfo.sysCustOption.dutyState == 1 && childUserlist.length > 0">
          <div class="layout-title">值班账号
          </div>
          <div class="layout-content">
            <div class="wp-table" v-loading="loading" element-loading-spinner="el-icon-loading" element-loading-text="正在加载">
              <el-table :data="childUserlist" stripe style="width: 100%">
                <el-table-column type="index" label="序号" width="70" align="center">
                </el-table-column>
                <el-table-column min-width="60" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="userName" min-width="200" label="人员姓名" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="loginName" label="登录账号" min-width="200" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="mobile" label="手机号码" min-width="200" align="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="100" align="left" show-overflow-tooltip>
                </el-table-column>

              </el-table>
              <div class="wp-noData" v-if="childUserlist.length == 0 && !loading">
                <img src="~@/assets/images/noData.png" alt="" />
                <p>暂无数据</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 汇总信息 -->
        <div class="layout-block wp-moudle-style">
          <div class="layout-title">汇总信息
          </div>
          <div class="layout-content">
            <div class="summary-box">
              <div class="right-item right-item-after">
                <div class="right-item-text">
                  <p>{{ personSummary.articleTaskMainCount }}</p>
                </div>
                <span>任务总量</span>
              </div>
              <div class="right-item right-item-after">
                <div class="right-item-text">
                  <p>{{ personSummary.articleTopicMainCount }}</p>
                </div>
                <span>上报总量</span>
              </div>
              <div class="right-item right-item-after">
                <div class="right-item-text">
                  <p>{{ personSummary.articleDrillMainCount }}</p>
                </div>
                <span>演练总量</span>
              </div>
              <div class="right-item right-item-after">
                <div class="right-item-text">
                  <p>{{ personSummary.totalScore }}</p>
                </div>
                <span>积分总数</span>
              </div>
              <div class="right-item right-item-after">
                <div class="right-item-text">
                  <p>{{ personSummary.yearScore }}</p>
                </div>
                <span>本年积分总数</span>
              </div>
              <div class="right-item">
                <div class="right-item-text">
                  <p>{{ personSummary.monthScore }}</p>
                </div>
                <span>本月积分总数</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 任务完成率 任务完成量-->
        <div class="layout-mid3 ">
          <div class="wp-moudle-style mid3-item">
            <div class="item-title home-title item-title-flex">
              <div>任务完成率</div>
              <div>
                <el-button-group>
                  <el-button size="small" class="wp-button-group wp-button-group-left" :class="{ 'active': days == 30 }" @click="handleTaskDays(30)">
                    近1月
                  </el-button>
                  <el-button size="small" class="wp-button-group wp-button-group-left" :class="{ 'active': days == 180 }" @click="handleTaskDays(180)">近6月
                  </el-button>
                  <el-button size="small" class="wp-button-group" :class="{ 'active': days == 365 }" @click="handleTaskDays(365)">近1年
                  </el-button>
                </el-button-group>
              </div>
            </div>
            <div class="item-content">
              <!-- <nightingale-chart ref="nightChart" id="nightChart" :pieData="taskPieData2">
							</nightingale-chart> -->
              <pie-ratio-chart ref="pieRatioChart" :pieData="taskPieData"></pie-ratio-chart>
            </div>
          </div>
          <div class="wp-moudle-style mid3-item">
            <div class="item-title home-title">任务完成量</div>
            <div class="item-content">
              <bar-chart ref="barChart" id="barChart" :xAxisData="taskXAxisData" :yAxisData="taskYAxisData">
              </bar-chart>
            </div>
          </div>
        </div>

      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import BarChart from '@/components/echartsComponents/barChart.vue';
import PieRatioChart from '@/components/echartsComponents/pieRatioChart.vue';
import * as personnelTeamApi from '@/api/personnelTeam.js'
export default {
  name: 'peopleInformation',
  components: { BarChart, PieRatioChart },
  data () {
    return {
      taskClassID: 1,
      taskXAxisData: [],
      taskYAxisData: [],
      srcList: [
      ],
      loading: false,
      taskPieData: [
        {
          value: 0,
          name: '任务完成率',
        },
        {
          value: 0,
          name: '任务未完成率',
        }
      ],
      userId: '',
      userObj: {},
      allUserObj: {},
      sysGroup: {},
      mediaList: [],
      childUserlist: [],
      personSummary: {}, // 汇总信息
      days: 30,  //传30近一月；180近三月；365近一年 //个人信息-任务完成率
      isShowTooltipWidth: false,
    };
  },
  props: {},
  created () {
    if (this.$route.query.userId) {
      this.userId = this.$decrypt(this.$route.query.userId)
      this.getDetail()
      this.getPersonSummary()
      this.getTaskCompleteRate()
      this.getTaskCompleteCount()
    } else {
      this.goback()
    }
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['sysUserInfo'])
  },
  methods: {
    // 获取用户信息
    async getDetail () {
      try {
        let res = await personnelTeamApi.getSysUser({ userId: this.userId })
        if (Number(res.returnCode) !== 200) return
        if (new Date(this.$utils.resetTime(res.returnData.sysUser.loginTime)).getTime() == new Date(this.$utils.resetTime(res.returnData.sysUser.createTime)).getTime()) {
          res.returnData.sysUser.loginTime = null
        } else {
          res.returnData.sysUser.loginTime = this.$moment(this.$utils.resetTime(res.returnData.sysUser.loginTime)).format("YYYY-MM-DD HH:mm")
        }
        this.userObj = res.returnData.sysUser
        this.sysGroup = res.returnData.sysGroup
        this.allUserObj = res.returnData
        this.setInitValue()
        // this.getAsideList()
      } catch (error) {
        console.log(error)
      }
    },
    // 设置初始值
    setInitValue () {
      // 文件的回显
      // archive  this.userObj.archive
      this.srcList = this.userObj.archive ? JSON.parse(this.userObj.archive).map(it => `${process.env.VUE_APP_BASE_API}/file/${it.fileId}`) : []
      console.log(this.srcList)
      this.mediaList = this.$utils.supGroup(this.allUserObj.sysUserMediaList, 2)
      this.childUserlist = this.allUserObj.sysUserList

    },
    // 获取用户信息-汇总
    async getPersonSummary () {
      try {
        let res = await personnelTeamApi.personSummary({ userId: this.userId })
        if (Number(res.returnCode) !== 200) return
        this.personSummary = res.returnData
      } catch (error) {
        console.log(error)
      }
    },
    // 个人信息-任务完成率
    async getTaskCompleteRate () {
      let query = {
        days: this.days,
        userId: this.userId
      }
      try {
        let res = await personnelTeamApi.taskCompleteRate(query)
        if (Number(res.returnCode) !== 200) return
        this.taskPieData = [
          {
            value: res.returnData.completeCount,
            name: '任务完成率',
          },
          {
            value: res.returnData.notCompleteCount,
            name: '任务未完成率',
          }
        ]
        this.$nextTick(() => {
          this.$refs?.pieRatioChart?.setData()
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 个人信息-任务完成数量
    async getTaskCompleteCount () {
      let query = {
        days: this.days,
        userId: this.userId
      }
      try {
        let res = await personnelTeamApi.taskCompleteCount(query)
        if (Number(res.returnCode) !== 200) return
        // 取后面6个？
        let dataArray = res.returnData.slice(-6)
        this.taskXAxisData = dataArray.map(it => this.$moment(it.name).format('M月'))
        this.taskYAxisData = dataArray.map(it => it.count)
        this.$nextTick(() => {
          this.$refs?.barChart?.setData()
        })
      } catch (error) {
        console.log(error)
      }
    },
    handleClickItem () {
      this.$utils.hideImg()
    },
    goback () {
      this.$router.push('/teamlist')
    },
    handleTaskDays (days) {
      this.days = days
      this.getTaskCompleteRate()
    },
    // hover 是否显示全文
    visibilityChangeWidth (event) {
      this.isShowTooltipWidth = this.$utils.visibilityChange(event)
    }
  },
  beforeDestroy () {

  },
  watch: {
    // $route: {
    // 	handler (val, oldval) {
    // 		// console.log(val, oldval);
    // 		// if (val.meta.pPath && val.meta.pPath !== '/') {
    // 		// 	this.menuSelect(val.meta.pPath);
    // 		// } else {
    // 		// 	this.menuSelect(val.path);
    // 		// }
    // 	},
    // 	deep: true
    // }
  }
};
</script>
<style scoped lang="scss">
@import "./css/peopleInformation.scss";
</style>
