<template>
  <div class="login">
    <animation-page> </animation-page>
    <div class="login-box">
      <div class="login-top">
        <img src="~@/assets/images/mls/mlslogin.png" alt="" v-if="isMls" />
        <img src="~@/assets/images/login/logologin.png" alt="" v-else-if="!isMls" />
      </div>
      <div class="login-mid">
        <div class="login-img">
          <img src="~@/assets/images/login/loginImg.png" alt="" />
        </div>
        <div class="loginBox">
          <div class="login-title">欢迎登录</div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleform">
            <el-form-item prop="username">
              <el-input v-model.trim="ruleForm.username" ref="username" placeholder="请输入登录账号"
                prefix-icon="icon-yonghuming iconfont24" clearable @keyup.enter.native="handlerEnter" maxLength="20">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" ref="password" v-model.trim="ruleForm.password" show-password
                placeholder="请输入密码" prefix-icon="icon-mima iconfont24" clearable @keyup.enter.native="handlerEnter"
                maxLength="20"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <div class="verification-div">
                <el-input ref="code" v-model="ruleForm.code" placeholder="请输入验证码"
                  prefix-icon="icon-yanzhengma iconfont24" class="verification-input" clearable :maxlength="6"
                  @keyup.enter.native="handlerEnter" @input="resetNum">
                </el-input>
                <el-button v-if="!countdowning" type="primary" class="verification-btn" v-preventReClick
                  @click="sendMsg">
                  发送验证码</el-button>
                <el-button v-else type="primary" class="verification-btn">{{ remainingTime }}s</el-button>
              </div>
            </el-form-item>
          </el-form>
          <div class="tip-text" v-if="mobile">
            <p>
              验证码已发送至<span>{{ mobile }}</span>
            </p>
          </div>
          <div class="login-btn">
            <el-button v-if="!isLogining" type="primary" @click="doLogin" v-preventReClick>登录</el-button>
            <el-button v-else type="primary" :loading="isLogining">登录</el-button>
          </div>
          <div class="action-row">
            <div>
              <a href="javascript:;" @click="toForgetPassword">忘记密码？</a>
            </div>
            <div class="anzhuo">
              <el-popover placement="top" width="96" trigger="hover">
                <img :src="AndroidUrl" alt="" style="width: 100%; height: 100%" />
                <p slot="reference" class="flex-Acenter">
                  <span class="icon"><i class="icon-anzhuo"></i></span>Android
                </p>
              </el-popover>
            </div>
          </div>
          <div class="divider-div">
            <el-divider>技术支持:{{ serviceMobileN }}</el-divider>
          </div>
        </div>
      </div>
    </div>
    <change-password ref="changePassword" @success="refreshData" :serviceType="serviceType"></change-password>
  </div>
</template>

<script>
// import { mapGetters, mapState, mapMutations } from 'vuex';
import { resetNumber } from '@/utils/validate.js';
import { loginSend, serviceMobile } from '@/api/login.js';
import ChangePassword from '@/views/login/components/changePassword';
import AnimationPage from '@/views/login/components/animationPage';
import { isEnAndNumAndUnderline, verifyPassword } from '@/utils/validate.js';
import { isMls } from '@/settings.js';
import verifyLoginMixin from '@/components/mixin/verifyLogin.js';
export default {
  name: 'panel',
  mixins: [verifyLoginMixin],
  components: { ChangePassword, AnimationPage },
  data () {
    var validateLoginName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录账号'));
      } else if (!isEnAndNumAndUnderline(value)) {
        // callback(new Error('请输入英文或数字'));
        callback(new Error('账号错误,请重新输入'));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!verifyPassword(value)) {
        callback(new Error('密码错误,请重新输入'));
      } else {
        callback();
      }
    };

    return {
      isMls: isMls,
      remainingTime: 120, // 剩余时间
      countdowning: false,// 是否正在倒计时
      isLogining: false,// 是否正在登录中
      timer: null,
      mobile: '',
      ruleForm: {
        username: '',
        password: '',
        code: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入登录账号', trigger: 'blur' },
          { validator: validateLoginName, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' }
        ]
      },
      serviceMobileN: '',
      AndroidUrl: process.env.VUE_APP_ANDROID_URL,
      postSendMsging: false,
      serviceType: 1,
    };
  },
  props: {},
  created () {

    this.isLogin()
  },
  mounted () {
    this.getServiceMobile()
  },
  computed: {
  },
  methods: {
    // 获取客服电话
    async getServiceMobile () {
      let query = {
      }
      try {
        let res = await serviceMobile(query)
        if (res.returnCode != 1009 && res.returnCode != 200) return this.$toast(res.returnMsg)
        this.serviceMobileN = res.returnData[0].dataValue
      } catch (error) {
        console.log(error);
      }
    },
    // 回车
    handlerEnter () {
      if (!this.ruleForm.username) return this.$refs.username.focus()
      if (!this.ruleForm.password) return this.$refs.password.focus()
      if (!this.ruleForm.code) return this.$refs.code.focus()
      this.$refs.username.blur()
      this.$refs.password.blur()
      this.$refs.code.blur()
      this.doLogin()
    },
    // 登录验证
    doLogin () {
      let that = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {

          if (this.serviceType == 2) {
            that.postLoginWXG()
          } else {
            that.postLogin()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //发送登录
    postLogin () {
      if (this.isLogining) return
      this.isLogining = true
      let query = {
        username: this.ruleForm.username,
        password: this.ruleForm.password,
        code: this.ruleForm.code,
        source: 'web'
      }
      console.log(query)
      let that = this
      this.$store.dispatch('Login', query).then(res => {
        if (Number(res.returnCode) !== 200) {
          that.isLogining = false
          return that.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        let currRoleId = res.returnData.roleId
        // this.$refs.changePassword.show()
        // return false
        // 第一次登录必须修改密码
        if (res.returnData.firstLogin) {
          that.isLogining = false
          
          return this.$refs.changePassword.show()
        }

        this.$store.dispatch('GetUserList').then(res1 => {
          if (Number(res1.returnCode) !== 200) {
            that.isLogining = false
            return this.$showMessage({
              type: 'error',
              msg: res1.returnMsg
            })
          }
          // 一个用户都没有
          if (res1.returnData.length == 0) {
            that.isLogining = false
            return this.$showMessage({
              type: 'error',
              msg: '该账号异常'
            })
          }
          // 角色是超级管理员则直接跳转到 单位管理，没有切换账号页面
          // 判断是否是多角色，是多角色，需要跳转到切换登录页面
          if (currRoleId == 1) {
            // this.$router.push({
            //   name: 'unitManagList'
            // })
            this.goToPage(currRoleId)
          } else if (res1.returnData.length > 1) {
            this.$router.push({ name: 'switchLogin' })
          } else if (res1.returnData.length == 1) {
            //判断是哪种角色的用户
            // 角色ID；1超级管理员；2管理员；3组长；4网评员
            // 不是多角色，跳转到该用户的首页
            let roleId = res1.returnData[0].roleId
            this.goToPage(roleId)
            // if (roleId == 2) {
            //   this.$router.push({
            //     name: 'homeAdmin'
            //   })
            // } else {
            //   this.$router.push({
            //     name: 'home'
            //   })
            // }
          }

        })
      })
    },
    // 发送短信
    sendMsg () {
      let that = this
      let rules = ['username', 'password']
      let rulesLength = 0
      // 验证登录账号和 密码是否输入
      that.$refs['ruleForm'].validateField(rules, (res) => {
        if (!res) {
          rulesLength = rulesLength + 1
          if (rulesLength == rules.length) {
            that.postSendMsg()
          }
        }
      })
    },
    // 发送短信的请求
    async postSendMsg () {
      if (this.postSendMsging) return
      this.postSendMsging = true
      let query = {
        username: this.ruleForm.username,
        password: this.ruleForm.password
      }
      // console.log(query)
      try {
        let res = await loginSend(query)
        this.postSendMsging = false
        if (res.returnCode != 1009 && res.returnCode != 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.serviceType = res.returnData.serviceType //1.政务云 2.网宣格
        this.remainingTime = 120
        if (res.returnCode == 1009) {
          // 请求重复提交 计算倒计时时间
          // sendTime + 120 - thisTime= 剩余的秒数
          console.log(this.$moment(res.returnData.sendTime).diff(this.$moment(res.returnData.thisTime), 'seconds'))
          let diffSeconds = this.$moment(res.returnData.sendTime).diff(this.$moment(res.returnData.thisTime), 'seconds')
          this.remainingTime = diffSeconds + 120
        }
        this.mobile = res.returnData.mobile
        if (this.remainingTime <= 1) return
        this.countdowning = true
        //4.进入倒计时
        this.countDown();
      } catch (error) {
        console.log(error);
      }
    },
    // 清除定时器
    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    // 倒计时
    countDown: function () {
      this.clearTimer()
      let that = this;
      that.timer = setInterval(() => {
        if (that.remainingTime <= 1) {
          that.resetCountdowning()
        } else {
          that.remainingTime--;
        }
      }, 1000);
    },
    // 重置数值
    resetNum () {
      this.ruleForm.code = resetNumber(this.ruleForm.code);
    },
    toForgetPassword () {
      this.$router.push({ name: 'forgetPassword' })
    },
    resetData () {
      this.mobile = ''
    },
    // 重置倒计时
    resetCountdowning () {
      this.remainingTime = 120;
      this.clearTimer()
      this.countdowning = false;
      this.mobile = ''
    },
    refreshData () {
      this.resetCountdowning()
      this.$refs.ruleForm.resetFields()
      this.$store.dispatch('FedLogOut')
    },
    // // 验证是否已登录 已登录就跳转 没有登录就清除缓存
    async isLogin () {
      //  // 验证是否益阳用户 是益阳用户就直接跳转到益阳资源。在益阳登录失败要清空这个缓存
      //  if(localStorage.getItem('isWXG') && localStorage.getItem('isWXG') == 2){
      //  return window.location.href = window.location.origin+'/wxg/login'
      // }
      try {
        await this.getInfo()
      } catch (err) {
        console.log(err)
      }
    },
    postLoginWXG () {
      if (this.isLogining) return
      this.isLogining = true
      let query = {
        username: this.ruleForm.username,
        password: this.ruleForm.password,
        code: this.ruleForm.code,
        source: 'web'
      }
      let that = this
      this.$store.dispatch('LoginWXG', query).then(res => {
        if (Number(res.returnCode) !== 200) {
          that.isLogining = false
          return that.$showMessage({
            type: 'error',
            msg: res.returnMsg
          })
        }
        // 第一次登录必须修改密码
        if (res.returnData.firstLogin) {
          that.isLogining = false
          return this.$refs.changePassword.show()
        }
        // 跳转到益阳
        window.location.href = window.location.origin + '/wxg/login'
      })
    }

  },
  beforeDestroy () {
    this.clearTimer()
  },
  watch: {
    $route: {
      handler (val, oldval) {
        // console.log(val, oldval);
        // if (val.meta.pPath && val.meta.pPath !== '/') {
        // 	this.menuSelect(val.meta.pPath);
        // } else {
        // 	this.menuSelect(val.path);
        // }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
@import './css/login.scss';
</style>
