<template>
  <wp-dialog @cancel="cancel" title="修改密码" @determine="determine" width="420px" smallHeight="200px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="changePassword">
      <div class="tips">
        首次登录须修改密码，修改后使用新密码重新登录
      </div>
      <el-form :model="ruleForm3" :rules="rules3" ref="ruleForm3" class="ruleform3">
        <el-form-item prop="pass">
          <el-input type="password" v-model.trim="ruleForm3.pass" show-password placeholder="请输入新密码" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input type="password" v-model.trim="ruleForm3.checkPass" show-password placeholder="请重复输入新密码" clearable @keyup.enter.native="determine"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { verifyPassword, passwordLang } from '@/utils/validate.js';
import { firstLoginEditPwd } from '@/api/login.js';
import { firstLoginEditPwdWXG } from '@/api/wxgLogin.js';
export default {
  name: 'changePassword',
  components: {},
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!verifyPassword(value)) {
        callback(new Error(passwordLang));
      } else {
        if (this.ruleForm3.checkPass !== '') {
          this.$refs.ruleForm3.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (!verifyPassword(value)) {
        callback(new Error(passwordLang));
      } else if (value !== this.ruleForm3.pass) {
        callback(new Error('两次密码不一致，请重新输入!'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      ruleForm3: {
        pass: '',
        checkPass: '',
      },
      rules3: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
    };
  },
  props: {
    serviceType:{
      type:[String,Number],
      default:1
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    cancel () { this.handleClose() },
    determine () {
      this.$refs['ruleForm3'].validate((valid) => {
        if (valid) {
          this.postChange()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async postChange () {
      let query = {
        newPass: this.ruleForm3.pass
      }
      try {
        let apiName =  firstLoginEditPwd
        if(this.serviceType == 2){
          apiName = firstLoginEditPwdWXG
        }
        let res = await apiName(query)
        if (Number(res.returnCode) != 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$showMessage({
          type: 'success',
          msg: res.returnMsg
        })
        this.$emit('success')
        this.handleClose()
      } catch (error) {
        console.log(error);
      }
    },
    handleClose () {
      this.dialogVisible = false
      this.$store.dispatch('FedLogOut')
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {

    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm3', ['rules3'])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.changePassword {
  padding: 20px 50px 0px 50px;
  .tips {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #f35252;
    line-height: 20px;
    padding-bottom: 14px;
  }
}
</style>