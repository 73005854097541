<template>
  <div>
    <div class="video-box">
      <el-image
        @click="handleClickItem"
        class="video-img"
        fit="cover"
        :src="baseURL + file.picId"
      >
      </el-image>
      <img
        @click="playVideo"
        class="play"
        src="~@/assets/images/bofang.png"
        alt="播放"
      />
    </div>

    <!-- 播放视频弹窗 -->
    <div class="wp-mask" v-show="videoVisible">
      <img
        @click="hideMask"
        src="~@/assets/images/guanbi.png"
        class="close"
        alt="关闭"
      />

      <video
        ref="video"
        class="video-player"
        controls
        width="250"
        :src="videoUrl + file.id"
        type="video/mp4"
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WpVideo',
  props: {
    file: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      videoVisible: false,
      fileList: [],
      baseURL: process.env.VUE_APP_BASE_API + '/file/',
      videoUrl: process.env.VUE_APP_BASE_API + '/file/ios/download/'
    }
  },

  watch: {
    file: {
      handler (newVal) {
        console.log(newVal);
      },
      immediate: true,
      deep: true
    }
  },


  methods: {
    /** 点击播放按钮回调 */
    playVideo () {
      this.videoVisible = true
    },

    handleClickItem () {
      this.$utils.hideImg()
    },

    /** 关闭遮罩层 */
    hideMask () {
      this.videoVisible = false
      this.$refs.video.pause()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>