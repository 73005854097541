module.exports = {
  /* 用户信息加密key */
  secretkey: 'secretkeyqwertyuiopasdfghjklzxcvbnm',

  page: {
    pageSizeOpts: [10, 15, 30],
    pageSize: 30
  },

  crewPage: {
    pageSizeOpts: [10, 15, 30, 50],
    pageSize: 50
  },

  fileImgs: {
    xls: require('../assets/images/file/excel2.png'),
    word: require('../assets/images/file/word2.png'),
    pdf: require('../assets/images/file/pdf.png'),
    zip: require('../assets/images/file/zip.png'),
    mp4: require('../assets/images/file/mp4.png'),
    img: require('../assets/images/file/img.png')
  },

  // 指挥演练原头像加载失败替代的默认头像
  failAvatar: require('../assets/images/drill/default_avatar.png'),
  app: {
    downUrl: '/apkdown/roger.apk',
    userImportUrl: '/apkdown/user-import.xlsx',
    achievementImportUrl: '/apkdown/achievement-import.xlsx'
  },
  // 是否开启水印,true开启，false不开启
  watermark: true,
  //系统状态,默认为空，筛选项包括“试用、正式”  0.试用 1.正式
  systemStateList: [
    { label: "试用", value: "0" },
    { label: "正式", value: "1" }
  ],
  //启用状态,默认为空，筛选项包括“启用、禁用” 状态 0.删除 1.启用 2.禁用
  enableStateList: [
    { label: "启用", value: "1" },
    { label: "禁用", value: "2" }
  ],
  //用户启用状态,默认为空，筛选项包括“启用、禁用” 用户状态1.启用 2.禁用
  userEnableStateList: [
    { label: "启用", value: "1" },
    { label: "禁用", value: "2" }
  ],
  //短信渠道 默认“云目短信”，选项包括“湖南省办短信、云目短信”
  SMSList: [
    { label: "云目短信", value: "1" },
    { label: "湖南省办短信", value: "2" }
  ],
  //无星、一星、二星、三星、四星、五星
  starRatingList: [
    { label: "无星", value: "0" },
    { label: "一星", value: "1" },
    { label: "二星", value: "2" },
    { label: "三星", value: "3" },
    { label: "四星", value: "4" },
    { label: "五星", value: "5" }
  ],
  // Account status 启用、禁用 用户状态 0.删除 1.启用 2.禁用
  accountStatusList: [
    { label: "启用", value: "1" },
    { label: "禁用", value: "2" }
  ],
  // 人员性别 性别 1.男 2.女
  genderList: [
    { label: "男", value: "1" },
    { label: "女", value: "2" }
  ],
  // 1超级管理员；2管理员；3组长；4投稿员
  roleNames: {
    '1': "超级管理员",
    '2': "管理员",
    '3': "组长",
    '4': "投稿员"
  },
  //// 1）必选，默认“组员”，筛选项包括“组员、组长”
  roleList: [
    { value: '3', label: '组长' },
    { value: '4', label: '组员' }
  ],

  // 专题状态
  TOPICSTATUS: [
    { label: '已发布', id: "1" },
    { label: '已结束', id: "2" }
  ],

  // 质量评星
  STARLIST: [
    { id: 3, label: "优秀", star: 3 },
    { id: 2, label: "良好", star: 2 },
    { id: 1, label: "合格", star: 1 },
    { id: -1, label: "不合格", star: -1 },
    // { id: 0, label: "未评级", star: 0 },
  ],

  REVIEWSTATUS: [
    { label: '已通过', id: 1 },
    { label: '已驳回', id: 2 }
  ],
  REVIEW_CENTER_STATUS: [
    { label: '未读', id:'1' },
    { label: '已读', id: '2' },
    { label: '采用', id: '3' }
  ],
  // 优评状态 优评、未评选
  EXCELLENT_REVIEW_STATUS: [
    { label: '未评选', id: '1' },
    { label: '优评', id:'2' }
  ],
  EXCELLENT_STATUS:{
    EXCELLENT:2,  // 优评
    NO_EXCELLENT:1 // 未评选
  }
}
