<template>
  <div class="input-row flex-CB">
    <div class="input-item">
      <div class="file-item" :title="item.fileName" v-for="(item, index) in fileArr" :key="item.id">
        <p class="textOverOneLine">{{ item.name }}</p>
        <img src="~@/assets/images/guanbi.png" alt="" @click="deleteFile(index)" />
      </div>
    </div>
    <el-upload v-loading="loading" element-loading-spinner="el-icon-loading" class="avatar-uploader" :http-request="httpRequest" :action="action" :accept="accept" :show-file-list="false" :before-upload="handleBeforeUpload">
      <el-button class="wp-button wp-btn-icon" type="primary" icon="icon-daoru" size="medium" :disabled="fileArr.length >= fileMaxNum">
        选择文件
      </el-button>
    </el-upload>
  </div>

</template>

<script>
import { uploadFile } from '@/api/personnelTeam.js'
export default {
  props: {
    className: String,
    placeholder: {
      type: String,
      default: "请上传doc、docx格式文件"
    },
    // 图片列表
    fileList: [Array, String],
    // 支持上传格式
    accept: {
      type: String,
      default: '.doc, .docx'
    },
    // 文件类型限制 传image则限制图片类型
    fileType: {
      type: Array,
      default: () => ['doc', 'docx']
    },
    // 文件单个大小限制
    maxFile: {
      type: Number,
      default: 50
    },
    // 可上传文件最大数量
    fileMaxNum: {
      type: Number,
      default: 1
    },
  },
  data () {
    return {
      fileArr: [], // 文件集合
      isShow: true,
      action:process.env.VUE_APP_BASE_API + "/file/upload",
      remark: this.placeholder, // 提示语
      img: ['jpg', 'jpeg', 'png'],
      mp4: ['mp4'],
      word: ['doc', 'docx'],
      pdf: ['pdf'],
      xls: ['xlsx', 'xls'],
      zip: ['zip', 'rar'],
      baseURL: process.env.VUE_APP_BASE_API + '/file/',
      loading: false,
      isReset: false
    };
  },
  watch: {
    // 回显传入的文件集合
    fileList: {
      handler (newVal) {
        console.log(newVal)
        if (newVal?.length) {
          this.fileArr = this.$utils.setFileFileJson(newVal)
        }
      },
      deep: true,
      immediate: true
    },

    // 文件集合
    fileArr: {
      handler (newVal) {
        console.log(newVal);
        if (newVal?.length >= this.fileMaxNum) {
          this.isShow = false
        } else {
          this.isShow = true
        }
      },
      deep: true,
      immediate: true
    },
  },

  methods: {
    async httpRequest (option) {
      if (this.loading) return
      this.loading = true
      let fd = new FormData(); //参数的格式是formData格式的
      fd.append("file", option.file); //参数
      fd.append("id", '1'); //参数
      try {
        let res = await uploadFile(fd)
        this.loading = false
        // 上传成功
        if (res.returnCode == 200) {
          const ret = res.returnData
          const lastStr = ret.fileName.substring(ret.fileName.lastIndexOf('.') + 1).toLowerCase()
          let type = 'img'
          console.log(lastStr);
          if (this.img.indexOf(lastStr) != -1) {
            type = 'img'
          } else if (this.mp4.indexOf(lastStr) != -1) {
            type = 'mp4'
          } else if (this.word.indexOf(lastStr) != -1) {
            type = 'word'
          } else if (this.pdf.indexOf(lastStr) != -1) {
            type = 'pdf'
          } else if (this.xls.indexOf(lastStr) != -1) {
            type = 'xls'
          } else if (this.zip.indexOf(lastStr) != -1) {
            type = 'zip'
          }
          const fileObj = {
            id: ret.fileId,
            name: ret.fileName,
            type,
            picId: ret?.picId
          }

          this.fileArr.push(fileObj)
          this.$emit("fileChange", this.fileArr)
        } else {
          this.$showMessage({
            type: 'error',
            msg: `该文件上传异常`
          })
        }

      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },

    resetFile () {
      this.fileArr = []
      this.isShow = true
      this.loading = false
      this.isReset = true

      this.$emit("fileChange", [])
    },

    // 删除文件
    deleteFile (index) {
      this.fileArr.splice(index, 1)
      this.$emit("fileChange", this.fileArr)
    },

    /** 上传成功回调 */
    handleUploadSuccess (res, file) {
      console.log('上传成功回调 handleUploadSuccess')
      console.log(res, file);
      // 上传成功
      if (res.returnCode == 200) {
        const ret = res.returnData

        console.log(ret, file);
        const lastStr = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
        let type = 'img'
        console.log(lastStr);
        if (this.img.indexOf(lastStr) != -1) {
          type = 'img'
        } else if (this.mp4.indexOf(lastStr) != -1) {
          type = 'mp4'
        } else if (this.word.indexOf(lastStr) != -1) {
          type = 'word'
        } else if (this.pdf.indexOf(lastStr) != -1) {
          type = 'pdf'
        } else if (this.xls.indexOf(lastStr) != -1) {
          type = 'xls'
        } else if (this.zip.indexOf(lastStr) != -1) {
          type = 'zip'
        }
        console.log(ret, file, type);

        const fileObj = {
          id: ret.fileId,
          name: ret.fileName,
          type,
          picId: ret?.picId
        }

        console.log(res, file, fileObj)
        this.fileArr.push(fileObj)

        this.$emit("fileChange", this.fileArr)
      } else {
        this.$showMessage({ type: "error", msg: res.returnMsg })
      }
    },

    /** 上传前的回调 */
    handleBeforeUpload (file) {
      console.log('file============', file)
      console.log('file.size / 1024 / 1024==', file.size / 1024 / 1024)
      if(this.fileArr.length >= this.fileMaxNum){
        this.$showMessage({
          type: 'error',
          msg: '只能上传一个文件'
        })
        return false
      }
      let name = file.name.substring(0, file.name.lastIndexOf('.'))
      if (name.length > 100) {
        this.$showMessage({
          type: 'error',
          msg: '文件名过长'
        })
        return false
      }
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      let extension = true;

      console.log(this.fileType, testmsg)
      if (this.fileType.length > 0 && this.fileType.indexOf(testmsg) === -1) {
        extension = false;
      }

      console.log(extension);

      // 校验通过
      if (extension) {
        // 图片类型
        const isFile =
          testmsg == "doc" ||
          testmsg == "docx" 

        if (isFile) {
          const isMaxImg = file.size / 1024 / 1024 <= this.maxFile;
          console.log('isMaxImg==', isMaxImg)
          if (!isMaxImg) {
            this.$showMessage({ type: "warning", msg: `文件最大支持${this.maxFile}mb` })
          }
          return isMaxImg
        }
      } else {
        this.$showMessage({ type: "warning", msg: this.remark })
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .video-js .vjs-tech {
  object-fit: fill;
}
::v-deep .vjs-poster {
  background-size: cover;
}

.upload {
  width: 726px;
}

.flex-warp {
  display: flex;
  flex-wrap: wrap;
}

.image-list {
  display: inline;
  vertical-align: top;

  .image-item {
    display: inline-block;
    position: relative;
    margin-right: 20px;

    .avatar {
      width: 96px;
      height: 96px;
      display: block;
      border-radius: 4px;
    }

    .icon-guanbi {
      cursor: pointer;
      position: absolute;
      top: -9px;
      right: -9px;
      width: 20px;
      height: 20px;
    }
  }
}

/deep/ .video-js {
  width: 400px;
  height: 200px;
}

.avatar-uploader {
  display: inline-block;
  vertical-align: top;

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    width: 96px;
    height: 94px;
    line-height: 96px;
    font-size: 28px;
    color: #8c939d;
    border-radius: 4px;
    border: 1px dashed #dddddd;
    text-align: center;
  }
}

.mt-14 {
  margin-top: -14px;
}

.mt-0 {
  margin-top: 0px !important;
}

.file-placeholder {
  height: 24px;
  color: #cccccc;
  font-size: 14px;
  white-space: nowrap;
}

.input-row {
    display: flex;
    align-items: center;

    .input-item {
      display: flex;
      align-items: center;
      width: 220px;
      height: 36px; /*no*/
      border-radius: 4px;
      border: 1px solid #dddddd;
      padding-left: 8px;

      // border: 1px solid #F35454;
      .file-item {
        display: flex;
        align-items: center;
        width: 122px;
        height: 24px; /*no*/
        background: #f5f5f5;
        border-radius: 4px;
        padding-left: 4px;

        p {
          width: 84px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin-right: 10px;
        }

        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }

    .wp-button {
      margin-left: 12px;
    }
  }
</style>