<template>
  <wp-dialog @cancel="cancel" title="导入" @determine="determine" width="420px" smallHeight="170px" size="small" :dialogVisible="dialogVisible" @handleClose="handleClose">
    <div class="importUser" v-loading.fullscreen.lock="fullscreenLoading">
      <div class=" download">
        <a href="javascript:;" @click="downloadTemplate">下载模板</a>
      </div>
      <input v-show="false" type="file" name="file" accept=".xlsx" ref="uploadFile" @change="postExcel" />
      <el-form :model="ruleForm" ref="ruleForm" label-width="0px" class="ruleform" @submit.native.prevent>
        <el-form-item prop="name1" label="" :error="errorMsg">
          <div class="input-row">
            <div class="input-item">
              <div class="file-item" :title="item.fileName" v-for="(item, index) in uploadList" :key="item.id">
                <p class="textOverOneLine">{{ item.name }}</p>
                <img src="~@/assets/images/guanbi.png" alt="" @click="removeFile2(item, index)" />
              </div>
            </div>
            <el-button class="wp-button wp-btn-icon" :disabled="uploadList.length >= 1 || isUploading" type="primary" icon="icon-daoru" size="medium" @click="batchImport">
              选择文件
            </el-button>
          </div>
          <div class="tips" v-if="showMsgobj">
            <p v-if="isShowAllError">
              总数<span class="redColor">{{ msgobj.total }}</span>条，成功<span class="redColor">{{ msgobj.successCount
              }}</span>条，失败<span class="redColor">{{ msgobj.errorCount }}</span>条
            </p>
            <p v-else>
              导入失败
            </p>
            <a href="javascript:;" @click="downError" v-if="msgobj.errorCount > 0">下载失败数据</a>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </wp-dialog>
</template>

<script>
import { importUser } from '@/api/personnelTeam.js'

export default {
  name: 'importUser',
  components: {},
  data () {
    return {
      errorMsg: '',
      isEidt: false,
      dialogVisible: false,
      ruleForm: {
      },
      rules: {
      },
      uploadList: [],
      isUploading: false,// 是否正在上传
      msgobj: {},
      showMsgobj: false,
      formData: null,
      fullscreenLoading: false,
      isShowAllError: true

    };
  },
  props: {
    groupId: {
      type: [Number, String]
      // default: 0
    }
  },
  created () {

  },
  mounted () {

  },
  computed: {
  },
  methods: {
    // 导入文件
    batchImport: function () {
      // uploadFile
      this.$refs.uploadFile.click();
    },
    // 下载模板
    downloadTemplate () {
      this.$utils.download(`${process.env.VUE_APP_BASE_API}/file/userImportTemplate`)
    },
    // 选择文件
    postExcel: async function (event) {

      // lastModified: 1641881770520
      // lastModifiedDate: Tue Jan 11 2022 14:16:10 GMT+0800 (中国标准时间) {}
      // name: "201M.docx"
      // size: 210824136
      // type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      // webkitRelativePath: ""
      let fileList = event.target.files;
      console.log(fileList)
      this.errorMsg = ''
      //判断文件格式
      var testmsg = fileList[0].name.substring(fileList[0].name.lastIndexOf('.') + 1).toLowerCase();
      const extension2 = ['xlsx'].indexOf(testmsg) !== -1;
      if (!extension2) {
        this.$refs.uploadFile.value = '';
        this.$showMessage({
          type: 'error',
          msg: `请上传.xlsx格式文件!`
        })
        return
      }
      // if (this.size && fileList[0].size > this.size * 1024 * 1024) {
      //   this.$refs.uploadFile.value = '';
      //   this.$rogerAlert.alert({ type: 3, message: '文件最大上传' + this.size + 'mb' });
      //   return false;
      // }
      if (this.uploadList.length >= this.limit) {
        this.$refs.uploadFile.value = '';
        this.$showMessage({
          type: 'error',
          msg: '文件最多上传1个'
        })
        return false;
      }
      this.uploadList = []
      var formData = new FormData();
      formData.append('file', fileList[0]);
      formData.append('groupId', this.groupId);
      this.showMsgobj = false
      console.log(formData)
      this.formData = formData
      this.uploadList.push(fileList[0])
    },
    // 删除文件
    removeFile2: function (file, index) {
      this.showMsgobj = false
      console.log(file)
      //移除 数组中的数据

      let i = this.uploadList.indexOf(this.uploadList.find(s => s.name == file.name));
      if (i > -1) {
        this.uploadList.splice(i, 1);
      }
      this.$refs.uploadFile.value = '';
      // this.$emit('change-file-list', this.uploadList);
    },
    cancel () { this.handleClose() },
    determine () {
      this.showMsgobj = false
      this.errorMsg = ''
      if (this.uploadList.length == 0) {
        return this.errorMsg = '请导入文件'
      }
      this.postAdd()
    },
    handleClose () {
      this.$refs.uploadFile.value = '';
      this.dialogVisible = false
    },
    show () {
      this.dialogVisible = true
    },
    initDate () {

    },
    async postAdd () {
      // let query = {
      //   ...this.uploadList[0]
      // }
      try {
        this.fullscreenLoading = true
        let res = await importUser(this.formData)
        this.fullscreenLoading = false
        if (Number(res.returnCode) == 10028) {
          this.msgobj = res.returnData
          this.isShowAllError = false
          this.showMsgobj = true
          return
        }
        if (Number(res.returnCode) !== 200) return this.$showMessage({
          type: 'error',
          msg: res.returnMsg
        })
        this.$emit('success')
        if (res.returnData.errorCount == 0 && res.returnData.successCount > 0) {
          this.$showMessage({
            type: 'success',
            msg: res.returnMsg
          })
          this.handleClose()
          return
        }

        this.msgobj = res.returnData
        this.showMsgobj = true
      } catch (error) {
        this.fullscreenLoading = false
        console.log(error)
      }
    },
    // 下载失败数据
    downError () {
      let url = `${process.env.VUE_APP_BASE_API}/file/Excel${this.msgobj.excelUrl}`
      this.$utils.download(url)
    }
  },
  beforeDestroy () {

  },
  watch: {
    dialogVisible: {
      handler (val, oldval) {
        if (val) {
          this.initDate();
        } else {
          this.$reset('ruleForm', [])
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.importUser {
  padding: 12px 18px 0px 18px;

  .download {
    text-align: right;

    a {
      font-size: 14px;
      font-weight: 400;
      color: #0064e6;
    }
  }

  .ruleform {
    margin-top: 8px;
    padding: 20px 20px 0px 20px;
  }

  .input-row {
    display: flex;
    align-items: center;

    .input-item {
      display: flex;
      align-items: center;
      width: 220px;
      height: 36px; /*no*/
      border-radius: 4px;
      border: 1px solid #dddddd;
      padding-left: 8px;

      // border: 1px solid #F35454;
      .file-item {
        display: flex;
        align-items: center;
        width: 122px;
        height: 24px; /*no*/
        background: #f5f5f5;
        border-radius: 4px;
        padding-left: 4px;

        p {
          width: 84px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin-right: 10px;
        }

        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }

    .wp-button {
      margin-left: 12px;
    }
  }

  .tips {
    display: flex;
    align-items: center;

    > P {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-right: 14px;
    }

    .redColor {
      color: #f35454;
    }

    > a {
      font-size: 14px;
      font-weight: 400;
      color: #0064e6;
    }
  }
}
</style>