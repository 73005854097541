import { postWXG } from '@/utils/requestTwo'

// 登录接口
export function loginWXG (query) {
	return postWXG(`/login/auth`, query)
}

// 账号列表
export function getUserListWXG (query) {
	return postWXG(`/login/userList`, query)
}

// 找回密码 - 步骤2 - 发送验证码
export function sendFindWXG (query) {
	return postWXG(`/login/sendFind`, query)
}

// 找回密码 - 步骤3 - 校验验证码
export function checkFindCodeWXG (query) {
	return postWXG(`/login/checkFindCode`, query)
}
// 找回密码 - 步骤4 - 修改密码
export function getBackPassWXG (query) {
	return postWXG(`/login/getBackPass`, query)
}

// 第一次登录 - 修改密码
export function firstLoginEditPwdWXG (query) {
	return postWXG(`/sysUser/firstLoginEditPwd`, query)
}

//个人信息
export function getInfoWXG (query) {
	return postWXG(`/sysUser/info`, query)
}
