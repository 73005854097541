<template>
  <ul class="flex file-list">
    <li class="file-item" v-for="(item, index) in fileList" :key="item.id">
      <el-image
        v-if="item.type == 'img'"
        :key="item.id"
        class="w100"
        :src="baseURL + item.id"
        :preview-src-list="$utils.arrayRearrangement(index, imgList)"
      >
      </el-image>

      <img
        v-else-if="item.type == 'xls'"
        @click="download(item.id)"
        :src="$globalData.fileImgs.xls"
        class="w100"
        :title="item.name"
      />
      <img
        v-else-if="item.type == 'word'"
        @click="download(item.id)"
        :src="$globalData.fileImgs.word"
        class="w100"
        :title="item.name"
      />
      <img
        v-else-if="item.type == 'zip'"
        @click="download(item.id)"
        :src="$globalData.fileImgs.zip"
        class="w100"
        :title="item.name"
      />
      <img
        v-else-if="item.type == 'pdf'"
        @click="download(item.id)"
        :src="$globalData.fileImgs.pdf"
        class="w100"
        :title="item.name"
      />

      <Video v-if="item.type == 'mp4'" :file="item"></Video>
    </li>
  </ul>
</template>

<script>
import Video from '../video'
export default {
  components: { Video },
  props: {
    fileJson: [String, Array]
  },

  data () {
    return {
      fileList: [],
      imgList: [],
      videoList: [],
      baseURL: process.env.VUE_APP_BASE_API + '/file/'
    }
  },

  methods: {
    download (URL) {
      location.href = this.baseURL + URL
    }
  },

  watch: {
    fileJson: {
      handler (newVal) {
        console.log(newVal);
        this.fileList = []
        this.imgList = []
        this.videoList = []

        let list = []
        if (typeof newVal == 'string') {
          list = JSON.parse(newVal)
        } else {
          list = newVal
        }

        this.fileList = this.$utils.setFileFileJson(list)

        this.fileList.forEach(item => {
          if (item.type == 'img') {
            this.imgList.push(this.baseURL + item.id)
          }
        })

        this.videoList = this.fileList.filter(item => item.type == 'mp4')
        console.log(this.imgList, this.fileList);
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.w100 {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
</style>