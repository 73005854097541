import axios from 'axios'
import vue from 'vue'
import router from "../router"
import { getToken } from '@/utils/auth'
import utils from '@/utils/utils'

let baseURL = process.env.VUE_APP_BASE_API
axios.defaults.baseURL = baseURL;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

axios.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {

    if (config.url != '/handle/detail/update') {
      config.headers['Authorization'] = 'Bearer' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }

    if (config.url == '/file/fileUpload') {
      delete config.headers['Authorization']
    }
  }
  const { baseURL } = config.params || {};
  if (baseURL) {
    console.log('baseURL',baseURL)
    config.baseURL = baseURL;
  }
  return config
}, error => {
  Promise.reject(error)
})

const request = function (query) {
  return axios.request(query).then(res => {
    
    // 如果是用户信息接口 且 是在 第三方登录的路由发出的，那么就不跳转到登录页，由他自己去登录
    if(res.config.url == '/sysUser/info' && !router.app._route.meta.requireAuth && res.data.returnCode != 200) return Promise.resolve(res.data)
    if (res.data.returnCode == "1006") {
      vue.prototype.$showMessage({
        type: 'error',
        msg: '您的账号在其他地方登陆,请重新登陆',
      })
      router.push('/login')
    } else if (res.data.returnCode == "10002") {
      if (res.config.url != '/sysUser/info') {
        vue.prototype.$showMessage({
          type: 'error',
          msg: '登录已过期，请重新登录',
        })
      }
      router.push('/login')
    } else if ((res.config.url == "/login/userList" || res.config.url == '/sysUser/info') && res.data.returnCode != 200) {
      vue.prototype.$showMessage({
        type: 'error',
        msg: res.data.returnMsg,
      })
      if (router.app._route.fullPath == '/login') {
        window.location.reload()
      } else {
        router.push('/login')
      }
    }
    if (res.config.url == "/login/captcha") {
      return Promise.resolve(res)
    }
    return Promise.resolve(res.data)
  }).catch(e => {
    // console.log(e)
    vue.prototype.$showMessage({
      type: 'error',
      msg: '您当前的网络存在波动，请刷新页面或稍后重试!',
    })
    // vue.prototype.$message.error('您当前的网络存在波动，请刷新页面或稍后重试!')
    return Promise.reject(e.message)
  })
}

const post = function (url, params, encrypt = true) {
  // console.log(params);
  if (encrypt) {
    utils.paramsEncrypt(params)
  }
  const query = {
    url: url,
    method: 'post',
    withCredentials: true,
    // timeout: 30000,
    timeout: 30000,
    data: params,
    headers: { 'Content-Type': 'application/json' }
  }

  return request(query)
}


const getImg = function (url, params, encrypt = true) {
  if (encrypt) {
    utils.paramsEncrypt(params)
  }
  const query = {
    url: url,
    method: 'get',
    withCredentials: true,
    timeout: 30000,
    responseType: 'arraybuffer',
    params: params,
  }
  return request(query)
}
const get = function (url, params, encrypt = true) {
  if (encrypt) {
    utils.paramsEncrypt(params)
  }
  const query = {
    url: url,
    method: 'get',
    withCredentials: true,
    timeout: 30000,
    params: params,
  }
  return request(query)
}

const form = function (url, params) {
  const query = {
    url: url,
    method: 'post',
    withCredentials: true,
    timeout: 30000,
    data: params,
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  return request(query)
}

const postWXG = function (url, params, encrypt = true) {
  if (encrypt) {
    utils.paramsEncrypt(params)
  }
  const query = {
    url: url,
    method: 'post',
    withCredentials: true,
    // timeout: 30000,
    timeout: 30000,
    data: params,
    baseURL:process.env.VUE_APP_WXG_BASE_API,
    headers: { 'Content-Type': 'application/json' }
  }

  return request(query)
}

export {
  post,
  get,
  form,
  getImg,
  baseURL,
  postWXG
}
